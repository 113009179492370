import * as React from "react"
import "./Intro.scss"

const Intro: React.FunctionComponent = () => {
  return (
    <section className="offre__intro">
      <h1 className="heading heading--1">Les offres Greengo</h1>
      <article>
        <p>Pour les points de vente, la vente en ligne, ou les entreprises</p>
      </article>
    </section>
  )
}

export default Intro
