import * as React from "react"
import { DistributeursCard, EntreprisesCard } from "../contact/ContactCard"
import "./HowMuch.scss"

const HowMuch: React.FunctionComponent = () => {
  return (
    <section className="howmuch padding">
      <h2 className="heading heading--2 devis-title">Demander un devis</h2>
      <section className="contactus__items">
        <EntreprisesCard />
        <DistributeursCard />
      </section>
    </section>
  )
}

export default HowMuch
