import React from "react"
import Footer from "../components/Footer"
import Layout from "../components/Layout"
import NavBar from "../components/NavBar"
import HowMuch from "../components/pages/nos-offres-pro/HowMuch"
import Intro from "../components/pages/nos-offres-pro/Intro"
import WhatIsIt from "../components/pages/nos-offres-pro/WhatIsIt"
import SEO from "../components/Seo"
import "./nos-offres-pro.scss"

const AuCoeurDeGreengo = () => (
  <Layout>
    <SEO
      title="Nos offres partenaires pour une solution zéro déchet"
      description="Nos offres pour les points de vente, la vente en ligne, et les entreprises"
      keywords={[
        "consigne",
        "emballage",
        "zéro",
        "déchets",
        "restauration",
        "btb",
        "b2b",
      ]}
    />
    <div className="offres__background">
      <NavBar activePage="nos-offres-pro" />
      <Intro />
      <WhatIsIt />
      <HowMuch />
      <Footer />
    </div>
  </Layout>
)

export default AuCoeurDeGreengo
