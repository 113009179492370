import * as React from "react"
import kit from "../../../images/index/offre1.svg"
import offre2 from "../../../images/index/offre2.svg"
import service from "../../../images/index/offre3.svg"
import plus from "../../../images/shared/plus.svg"
import "./WhatIsIt.scss"

const WhatIsIt: React.FunctionComponent = () => {
  return (
    <section className="whatisit padding">
      <h2 className="heading heading--2 heading--svg heading--svg-underline">
        Qu'est-ce que c'est ?
      </h2>
      <section className="nos-offres__items">
        <article className="nos-offres__item">
          <h3 className="heading heading--3">Le kit Greengo</h3>
          <div className="nos-offres__image nos-offres__image--kit">
            <img src={kit} alt="Kit Greengo" />
          </div>
          <div className="double-paragraph">
            <p>Mise à disposition d’un collecteur intelligent</p>
            <p>Mise à disposition d’emballages réutilisables</p>
          </div>
        </article>

        <img className="plus" src={plus} alt="+" />
        <article className="nos-offres__item">
          <h3 className="heading heading--3">
            Le logiciel d'exploitation Greengo
          </h3>
          <div className="nos-offres__image nos-offres__image--logiciel">
            <img src={offre2} alt="logiciel d'exploitation" />
          </div>
          <p>
            <ol>
              <li>Tracking individualisé à l’emballage</li>
              <li>Remboursement de la consigne à chaque utilisateur</li>
              <li>Intégration au Click & Collect</li>
            </ol>
          </p>
        </article>

        <img className="plus" src={plus} alt="+" />
        <article className="nos-offres__item">
          <h3 className="heading heading--3">
            Service opérationnel (optionnel)
          </h3>
          <div className="nos-offres__image nos-offres__image--service">
            <img src={service} alt="service opérationnel" />
          </div>
          <p>Gestion de la logistique et du nettoyage des emballages</p>
        </article>
      </section>
    </section>
  )
}

export default WhatIsIt
